@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=block);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700&display=block);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}

html,
body,
main,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid white;
  -webkit-text-fill-color: white;
  /* -webkit-box-shadow: 0 0 0px 1000px #224757 inset; */
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}
.loader {
  color: #fefefe;
  font-size: 20px;
  margin: 100px auto;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em,
      -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em,
      -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0,
      -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em,
      -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em,
      -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0,
      -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

@font-face {
  font-family: 'WorkSans-Regular';
  src: local('WorkSans-Regular'), url(/static/media/WorkSans-Regular.1cafe0d5.ttf) format('opentype');
}

@font-face {
  font-family: 'WorkSans-Bold';
  src: local('WorkSans-Bold'), url(/static/media/WorkSans-Bold.843584d3.ttf) format('opentype');
}

@font-face {
  font-family: 'WorkSans-ExtraBold';
  src: local('WorkSans-ExtraBold'), url(/static/media/WorkSans-ExtraBold.0316054f.ttf) format('opentype');
}

@font-face {
  font-family: 'WorkSans-Medium';
  src: local('WorkSans-Medium'), url(/static/media/WorkSans-Medium.d7a97380.ttf) format('opentype');
}

@font-face {
  font-family: 'WorkSans-SemiBold';
  src: local('WorkSans-SemiBold'), url(/static/media/WorkSans-SemiBold.3cdc6ded.ttf) format('opentype');
}

@font-face {
  font-family: 'WorkSans-Light';
  src: local('WorkSans-Light'), url(/static/media/WorkSans-Light.485b5eb0.ttf) format('opentype');
}

@font-face {
  font-family: 'WorkSans-Black';
  src: local('WorkSans-Black'), url(/static/media/WorkSans-Black.cc5e8bac.ttf) format('opentype');
}

input {
  background-color: #224757;
}

input:-webkit-autofill {
  background-color: #224757;
}
.container {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-bottom: 0px;
  cursor: pointer;
  /* font-size: 10px; */
  text-align: center;
  background-color: 'pink';
  font-family: 'Worksans-Regular';
}

/* Hide the default checkbox */
.container input {
  visibility: hidden;
  cursor: pointer;
}

/* Create a custom checkbox */
.mark {
  position: absolute;
  top: 0;
  left: 0;
  /* margin-top: 2px; */
  /* height: 2.5vh;
  width: 2.5vh; */
  background-color: #224957;
  border-radius: 5px;
}
@media (min-width: 576px) {
  .mark {
    width: 2.5vh;
    height: 2.5vh;
  }
}
@media (min-width: 1920px) {
  .mark {
    width: 2.3vh;
    height: 2.3vh;
  }
}

.container:hover input ~ .mark {
  background-color: #224957;
}

.container input:checked ~ .mark {
  background-color: #224957;
}

/* Create the mark/indicator (hidden when not checked) */
.mark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the mark when checked */
.container input:checked ~ .mark:after {
  display: block;
}

/* Style the mark/indicator */
.container .mark:after {
  left: 7px;
  top: 2px;
  width: 3px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.carousel .thumbs-wrapper {
  /* margin: 2vh; */
  margin: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  white-space: wrap;
}
.carousel .thumb {
  transition: border 0.15s ease-in;
  display: inline-block;
  margin-right: 2vh;
  white-space: nowrap;
  overflow: hidden;
  border: 0.3vh solid #fff;
  /* padding: 3vh; */
}
.thumb {
  width: 10vh !important;
}
.carousel.carousel-slider {
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel .slider-wrapper.axis-horizontal .slider {
  display: flex;
  align-items: center;
}

