input {
  background-color: #224757;
}

input:-webkit-autofill {
  background-color: #224757;
}
.container {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-bottom: 0px;
  cursor: pointer;
  /* font-size: 10px; */
  text-align: center;
  background-color: 'pink';
  font-family: 'Worksans-Regular';
}

/* Hide the default checkbox */
.container input {
  visibility: hidden;
  cursor: pointer;
}

/* Create a custom checkbox */
.mark {
  position: absolute;
  top: 0;
  left: 0;
  /* margin-top: 2px; */
  /* height: 2.5vh;
  width: 2.5vh; */
  background-color: #224957;
  border-radius: 5px;
}
@media (min-width: 576px) {
  .mark {
    width: 2.5vh;
    height: 2.5vh;
  }
}
@media (min-width: 1920px) {
  .mark {
    width: 2.3vh;
    height: 2.3vh;
  }
}

.container:hover input ~ .mark {
  background-color: #224957;
}

.container input:checked ~ .mark {
  background-color: #224957;
}

/* Create the mark/indicator (hidden when not checked) */
.mark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the mark when checked */
.container input:checked ~ .mark:after {
  display: block;
}

/* Style the mark/indicator */
.container .mark:after {
  left: 7px;
  top: 2px;
  width: 3px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
