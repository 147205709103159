.carousel .thumbs-wrapper {
  /* margin: 2vh; */
  margin: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  white-space: wrap;
}
.carousel .thumb {
  transition: border 0.15s ease-in;
  display: inline-block;
  margin-right: 2vh;
  white-space: nowrap;
  overflow: hidden;
  border: 0.3vh solid #fff;
  /* padding: 3vh; */
}
.thumb {
  width: 10vh !important;
}
.carousel.carousel-slider {
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel .slider-wrapper.axis-horizontal .slider {
  display: flex;
  align-items: center;
}
